import ApiService from "@/core/services/ApiService";
import { processErrors } from "@/api/errorsProcessing";
import { Facility } from "@/modules/facility/facility.model";

const url = "api/v1/facilities";

export async function searchFacilities(request) {
  return await ApiService.post(url + "/search", request as never).then(
    (res) => {
      const data = res.data;
      //TODO DEBT
      data.data.forEach((item: any) => {
        item.effectiveDate = new Date(item.effectiveDate);
        item.termDate = new Date(item.termDate);
      });

      return data;
    }
  );
}

export async function getFacilities() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never).then(
    (res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        item.effectiveDate = new Date(item.effectiveDate);
        item.termDate = new Date(item.termDate);
      });

      return items;
    }
  );
}

export async function getFacility(facilityId): Promise<Facility> {
  return await ApiService.get(url + "/" + facilityId)
    .then((res) => {
      const facility = res.data;
      if (facility.effectiveDate)
        facility.effectiveDate = new Date(facility.effectiveDate);
      if (facility.terminationDate)
        facility.terminationDate = new Date(facility.terminationDate);

      return facility;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getDefaultFacility(): Promise<Facility> {
  return await ApiService.get(url + "/GetDefault")
    .then((res) => {
      const facility = res.data;
      if (facility.effectiveDate)
        facility.effectiveDate = new Date(facility.effectiveDate);
      if (facility.terminationDate)
        facility.terminationDate = new Date(facility.terminationDate);

      return facility;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addFacility(facility) {
  return await ApiService.post(url, facility as never).catch(({ response }) => {
    processErrors(response);
  });
}

export async function fillCodes(facilityId) {
  return await ApiService.post(url + "/fillCodes", {FacilityId: facilityId} as any).catch(({ response }) => {
    processErrors(response);
  });
}

export async function checkDefaultCodesCodes(facilityId) {
  return await ApiService.post(url + "/CheckDefaultCodesCodes", {FacilityId: facilityId} as any).then((res) => {
    return res.data;
  }).catch(({ response }) => {
    processErrors(response);
  });
}

export async function updateFacility(facility) {
  if (facility) {
    return await ApiService.put(
      url + "/" + facility.id,
      facility as never
    ).catch(({ response }) => {
      processErrors(response);
    });
  }
}

export async function deleteFacility(facility) {
  if (facility) {
    return await ApiService.delete(url + "/" + facility?.id).catch(
      ({ response }) => {
        processErrors(response);
      }
    );
  }
}
